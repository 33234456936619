import React, { ReactElement } from 'react'

import Template from '@/components/app/Template'
import { getPage } from '@/themes'
import { HomePageProps } from '@/types/pages/home'

/**
 * Home page
 */
export default function Home(props: HomePageProps): ReactElement {
  const HomePage = getPage<HomePageProps>(`Home`)
  return (
    <Template {...props}>
      <HomePage {...props} />
    </Template>
  )
}
