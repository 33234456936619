import React, { ReactElement, useEffect } from 'react'

import { useContext } from '@/components/app/context'
import { BuilderTemplateProps, TemplateProps } from './types'

/**
 * Template wrapper
 */
export default function Template({
  children,
  pageContext,
}: TemplateProps): ReactElement {
  const [, dispatch] = useContext()
  useEffect(() => {
    dispatch({
      type: `SetMenu`,
      payload: pageContext.menu,
    })
    dispatch({
      type: `SetSiteSettings`,
      payload: pageContext.siteSettings,
    })
  }, [])
  return <>{children}</>
}

/**
 * Builder template
 */
export function BuilderTemplate({
  children,
  pageContext,
}: BuilderTemplateProps): ReactElement {
  const [, dispatch] = useContext()
  useEffect(() => {
    dispatch({
      type: `SetBuilder`,
      payload: pageContext,
    })
  }, [])
  return <>{children}</>
}
